<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <div class="input-group">
            <span class="input-group-text">{{ this.currency }}</span>
            <input
                type="text"
                class="input form-control"
                :id="`field_${this.id}`"
                :name="this.name"
                :readonly="!this.editing || this.readonly"
                :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
                :autocomplete="this.autocomplete"
                :maxlength="this.maxlength"
                :aria-describedby="error.error ? `error_${this.id}` : null"
                v-model="input"
                @keypress="isNumber($event)"
                @keyup="formatCurrency($event) && validate($event)"
                @blur="formatCurrency($event, 'blur') && validate($event)"
                @change="handleChange"
            />
        </div>
        <errorElement v-bind="resolveProps('error', this)" :error="error" :errorId="`error_${this.id}`" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>


<script>
import {ref, watch, computed} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {
            currency: {
                type: String,
                required: false,
                default: "€"
            }
        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        const isNumber = (e) => {
            e = e ? e : window.event;
            const charCode = (e.which) ? e.which : e.keyCode;
            // 44 = comma
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                e.preventDefault();
            } else {
                return true;
            }
        };


        const formatNumber = (n) => {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        };


        const formatCurrency = (e, blur) => {
            // Detect CTRL+A, 17 = Control key, 65 = a
            if (e.keyCode == 17 || e.ctrlKey || e.keyCode == 65 && e.ctrlKey) return;

            // Define our variables
            const input = e.target;
            let value = input.value;

            // don't validate empty input
            if (value === "") return;

            // original length
            const originalLength = value.length;

            // initial caret position
            let newPosition = input.selectionStart;

            // check for decimal
            if (value.indexOf(",") >= 0) {

                // Get position of first decimal, this prevents multiple decimals from being entered
                const decimal_pos = value.indexOf(",");

                // Split number by decimal point
                let left = value.substring(0, decimal_pos);
                let right = value.substring(decimal_pos);

                // Add dots to left side of number
                left = formatNumber(left);
                // Validate right side
                right = formatNumber(right);
                // On blur make sure 2 numbers after decimal
                if (blur === "blur") right += "00";
                // Limit decimal to only 2 digits
                right = right.substring(0, 2);
                // Set value
                value = left + "," + right;

            } else {
                // No decimal entered, so we add dots to our number and remove all non-digits
                value = formatNumber(value);

                // Final formatting
                if (blur === "blur") value += ",00";
            }

            // send updated string to input
            input.value = value;

            // Put caret back in the right position
            newPosition = value.length - originalLength + newPosition;
            input.setSelectionRange(newPosition, newPosition);
        };


        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: input.value
            });
        }

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            trans,
            input,
            error,
            resolveProps,
            validate,
            isNumber,

            formatCurrency,

            handleChange,
            placeholder
        };
    },
}
</script>