<template>
    <div class="AddressFieldElement" :data-required="this.required" :class="{ error : error.error }">
        <div class="cs_formgrid">
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label v-if="this.show_label" :for="`field_${this.id}_street`">{{ trans("Straat") }}</label>
                    <input
                        type="text"
                        class="input"
                        :id="`field_${this.id}_street`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :placeholder="`${this.show_placeholder ? trans('Straat') + (this.required ? ' *' : '') : ''}`"
                        v-model="addressRefs.street"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span3">
                <div class="element AddressField" :class="{required: this.required}">
                    <label v-if="this.show_label" :for="`field_${this.id}_housenumber`">{{ trans("Huisnummer") }}</label>
                    <input
                        type="text"
                        class="input"
                        :id="`field_${this.id}_housenumber`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :placeholder="`${this.show_placeholder ? trans('Huisnummer') + (this.required ? ' *' : '') : ''}`"
                        v-model="addressRefs.housenumber"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span3">
                <div class="element AddressField">
                    <label v-if="this.show_label" :for="`field_${this.id}_housenumber_suffix`">{{ trans("Toevoeging") }}</label>
                    <input
                        type="text"
                        class="input"
                        :id="`field_${this.id}_housenumber_suffix`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :placeholder="`${this.show_placeholder ? trans('Toevoeging') : ''}`"
                        v-model="addressRefs.housenumber_suffix"
                    />
                </div>
            </div>
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label v-if="this.show_label" :for="`field_${this.id}_zipcode`">{{ trans("Postcode") }}</label>
                    <input
                        type="text"
                        class="input"
                        :id="`field_${this.id}_zipcode`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :placeholder="`${this.show_placeholder ? trans('Postcode') + (this.required ? ' *' : '') : ''}`"
                        v-model="addressRefs.zipcode"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span6">
                <div class="element AddressField" :class="{required: this.required}">
                    <label v-if="this.show_label" :for="`field_${this.id}_place`">{{ trans("Plaats") }}</label>
                    <input
                        type="text"
                        class="input"
                        :id="`field_${this.id}_place`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        :placeholder="`${this.show_placeholder ? trans('Plaats') + (this.required ? ' *' : '') : ''}`"
                        v-model="addressRefs.place"
                        @keyup="validateInput"
                        @blur="validateInput"
                    />
                </div>
            </div>
            <div class="span12">
                <div class="element AddressField" :class="{required: this.required}">
                    <label v-if="this.show_label" :for="`field_${this.id}_country`">{{ trans("Land") }}</label>
                    <select
                        :id="`field_${this.id}_country`"
                        :readonly="!this.editing || this.readonly"
                        :autocomplete="this.autocomplete"
                        v-model="addressRefs.country"
                        class="input"
                    >
                        <option value="NL">{{ trans("Nederland") }}</option>
                        <option value="BE">{{ trans("België") }}</option>
                        <option value="FR">{{ trans("Frankrijk") }}</option>
                        <option value="DE">{{ trans("Duitsland") }}</option>
                        <option value="GB">{{ trans("Verenigd Koningrijk") }}</option>
                        <option value="IT">{{ trans("Italië") }}</option>
                        <option value="ES">{{ trans("Spanje") }}</option>
                    </select>
                </div>
            </div>
        </div>
        <input
            type="hidden"
            readonly
            :name="this.name"
            :autocomplete="false"
            :aria-describedby="error.error ? `error_${this.id}` : null"
            v-model="input"
        />
        <errorElement v-bind="resolveProps('error', this)" :error="error" :errorId="`error_${this.id}`" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch, reactive} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import trans from "helpers/_translation";
import { mergeDeep } from "helpers/_objects";

import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        /*
        "country",
        "zipcode",
        "street",
        "housenumber",
        "housenumber_suffix",
        "place",
        "province"
        */

        const input = ref("");
        const {validateTextField, error} = useFieldValidation();
        const validateInput = (e) => {
            if (props.required) {
                validateTextField(props.label, e.target.value);
            }
        };

        // Place is unused at the moment
        const addressRefs = reactive({
            street: null,
            housenumber: null,
            housenumber_suffix: null,
            zipcode: null,
            place: null,
            province: null,
            country: null
        });


        watch(
            () => props.value,
            () => {
                input.value = props.value;
                if (props.value) {
                    const addressData = JSON.parse(props.value);
                    Object.keys(addressRefs).forEach((key, index) => {
                        addressRefs[key] = addressData[key];
                    });
                }
                if (!addressRefs.country) {
                    addressRefs.country = "NL";
                }
            },
            {
                immediate: true
            }
        );

        const setAddress = () => {
            input.value = JSON.stringify(addressRefs);
        };

        Object.keys(addressRefs).forEach(key => {
            watch(
                () => addressRefs[key],
                setAddress
            );
        });



        return {
            input,
            error,
            resolveProps,
            validateInput,

            addressRefs,
            trans
        };
    },
}
</script>